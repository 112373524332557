import React, { useState, useEffect } from "react";
import {
    Dialog,
    DialogContent,
    DialogContentText,
    DialogActions,
    Button,
} from "@material-ui/core";
import featureServer from "../../../../../appUtility/featureServer";
import { history } from "../../../../../../src/store/index";


const StockMessage = (props) => {
    const [details, setDetails] = useState({
        open: props && props.open,
    });
    const [showButton, setShowButton] = useState(false);

    useEffect(() => {
        props.sendToStock(details);
    }, []);


    const handleDismiss = () => {
        setDetails({
            ...details,
            open: false,
        });
        // localStorage.setItem("stock_attention", JSON.stringify("false"));
    };

    const handleStockTake = () => {
        // localStorage.setItem("stock_attention", JSON.stringify("false"));
        history.push(`/app/inventory/stock_take`);
    };


    useEffect(() => {
        const checkTime = () => {

            const now = new Date();
            const options = { timeZone: 'Australia/Sydney', year: 'numeric', month: '2-digit', day: '2-digit', hour: '2-digit', minute: '2-digit', second: '2-digit', hour12: false };
            const formatter = new Intl.DateTimeFormat('en-AU', options);
            const parts = formatter.formatToParts(now);
            const dateParts = parts.reduce((acc, part) => {
                acc[part.type] = part.value;
                return acc;
            }, {});

            const currentDate = new Date(
                `${dateParts.year}-${dateParts.month}-${dateParts.day}T${dateParts.hour}:${dateParts.minute}:${dateParts.second}+10:00` // +10:00 for AEST
            );

            const targetDate = new Date('2024-08-15T00:00:00+10:00');


            if (currentDate >= targetDate) {
                setShowButton(true);
            } else {
                setShowButton(false);
            }
        };

        checkTime();
        const intervalId = setInterval(checkTime, 60000);
        return () => clearInterval(intervalId);
    }, []);


    return (
        <Dialog maxWidth="md" open={details.open} onClose={false}>
            <div className="p-2">
                <DialogContent>
                    {/* {featureServer && ( */}
                    <DialogContentText style={{ color: "#444" }}>
                        <h2>Empower Your Pet Grooming Business: Quarterly Stock Take for Unleashed Success!</h2>

                        <p>Keeping your inventory in check every quarter not only ensures smooth operations but also highlights your commitment to excellence.</p>

                        <p>Embrace the power of regular stock takes to stay organized, reduce waste, and provide the best for your furry clients.</p>

                        <p>Let's make each quarter count towards a more efficient and prosperous grooming business!</p>


                        {/* <h1>Introducing Inventory Usage Tracking: A New Feature to Optimize Your Shampoo, Cologne and HydroClean Management</h1>

                        <p>We are excited to announce that we have added a new feature to our Mate system that helps you track and manage your Shampoo, Cologne and HydroClean usage and orders. Inventory usage tracking is a powerful tool that allows you to monitor how much Shampoo, Cologne and HydroClean you are using, how much Shampoo, Cologne and HydroClean you have left, and how much Shampoo, Cologne and HydroClean you need to order.</p>

                        <p><strong>Inventory usage tracking can help you:</strong></p>
                        <ul>
                            <li>Reduce inventory costs by avoiding overstocking or understocking.</li>
                            <li>Improve inventory accuracy by tracking inventory movements and adjustments.</li>
                            <li>Increase inventory efficiency by optimizing inventory replenishment and allocation.</li>
                            <li>Enhance inventory visibility by generating inventory usage reports and dashboards.</li>
                        </ul>

                        <p>This feature will be enabled on <em>1st July 2024</em> automatically and you don’t have to do anything. Once enabled, it will suggest you make a one-time “Stock Take” to estimate how much Shampoo, Cologne and HydroClean you currently have, to predict your next refill timeline.</p>

                        <p>You can configure your inventory usage per service type via <em>setup -&gt; Inventory usage</em>. This will have default values set by support office which you are free to optimize as per your needs.</p>

                        <p>We hope you enjoy this new feature and find it useful for your inventory management.</p> */}



                    </DialogContentText>

                </DialogContent>
                <DialogActions style={{ display: "flex", alignItems: "center", justifyContent: "space-between" }}>
                    {!showButton &&
                        <div style={{ marginLeft: "15px" }}><strong>Deadline: 14th August 2024</strong></div>
                    }
                    <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-end" }}>
                        <Button variant="contained" color="primary" onClick={handleStockTake}>
                            Stock Take Now
                        </Button>
                        {!showButton &&
                            <Button variant="contained" style={{ marginLeft: "10px" }} color="primary" onClick={handleDismiss}>
                                Dismiss
                            </Button>
                        }
                    </div>

                </DialogActions>
            </div>
        </Dialog>
    );
};

export default StockMessage;
